import { Field, Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Label,
  Table,
} from "reactstrap";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { getRoleListRequest } from "../../../redux/admin/role/action";
import { connect } from "react-redux";
import { addUserListRequest } from "../../../redux/admin/user/action";
import { addUserInitialState } from "./CommonFunction";
import {
  AdminAccess,
  SuperAdminAccess,
} from "../../../constant/commonConstants";

function AddUser(props) {
  const {
    onModalDismiss,
    RoleListRequest,
    dispatchData,
    AddUserStatus,
    AddUserRequest,
    getRoleList,
    setIsEdit,
  } = props;
  const IsEditData = props?.data?.id;
  const [closeModal, setCloseModal] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [UserData] = useState(addUserInitialState(props?.data));
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      values = {
        ...values,
        moduleAccess: {
          editVendorContacts: values.editVendorContacts,
          addVendorContacts: values.addVendorContacts,
          achManagement: values.achManagement,
        },
      };
      AddUserRequest({ values, dispatchData });

      setCloseModal(true);
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    RoleListRequest();
  }, [props.modalView === true]);
  useEffect(() => {
    if (closeModal === true) {
      onModalDismiss(false);
      setIsEdit(null);
      setCloseModal(false);
    }
  }, [AddUserStatus === true]);

  return (
    <>
      <Formik initialValues={UserData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            <Form className="form-horizontal form-login">
              <div className="category-head">
                <FormGroup className=" mb-3">
                  <Field
                    type="fullName"
                    id="fullName"
                    name="fullName"
                    className="form-control"
                    placeholder="Full Name"
                  />
                  <Label className="form-label" htmlFor="fullname">
                    Full Name
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t("validations.user.fullName"),
                    values.fullName,
                    "required",
                    {
                      messages: {
                        required: __t("validations.user.fullName"),
                      },
                    }
                  )}
                </FormGroup>

                <FormGroup className=" mb-3">
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    placeholder="Email Address"
                  />
                  <Label className="form-label" htmlFor="email">
                    Email Address
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t("validations.user.email"),
                    values.email,
                    "required|email",
                    {
                      messages: {
                        required: __t("validations.user.email"),
                      },
                    }
                  )}
                </FormGroup>
                <FormGroup className="mb-3">
                  <Field
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    className="form-control"
                    placeholder="Mobile No."
                    maxLength={10}
                    minLength={10}
                  />

                  <Label className="form-label" htmlFor="phoneNumber">
                    Mobile No.
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    "phoneNumber",
                    values.phoneNumber,
                    "required|matches:^[0-9]{10}$|numeric",
                    {
                      messages: {
                        required: __t("validations.user.phoneNumber"),
                        matches: __t("validations.user.matches"),
                      },
                    }
                  )}
                </FormGroup>
                {/* <Table>
                                    <thead>
                                        <th>actions</th>
                                        <th>Admin</th>
                                        <th>Staff</th>
                                        <th>Marketing</th>
                                        <th>super Admin</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>update the vendor account number</p>
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="vendorAccess"
                                                    name="vendorAccess"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }} 
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="vendorAccess1"
                                                    name="vendorAccess1"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }} 
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="vendorAccess2"
                                                    name="vendorAccess2"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }} 
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="vendorAccess3"
                                                    name="vendorAccess3"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }} 
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>add the vendor contacts</p>
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="addVendorContacts"
                                                    name="addVendorContacts"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.addVendorContacts}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="addVendorContacts1"
                                                    name="addVendorContacts1"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.addVendorContacts}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="addVendorContacts2"
                                                    name="addVendorContacts2"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.addVendorContacts}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="addVendorContacts3"
                                                    name="addVendorContacts3"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.addVendorContacts}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>update the vendor contacts</p>
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="editVendorContacts"
                                                    name="editVendorContacts"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.editVendorContacts}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="editVendorContacts1"
                                                    name="editVendorContacts1"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.editVendorContacts}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="editVendorContacts2"
                                                    name="editVendorContacts2"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.editVendorContacts}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="editVendorContacts3"
                                                    name="editVendorContacts3"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    disabled={values.roleId == AdminAccess || values.roleId == SuperAdminAccess}
                                                    checked={values.editVendorContacts}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p>access the ACH Management</p>
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="achManagement"
                                                    name="achManagement"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    // disabled={values.roleId == SuperAdminAccess}
                                                    // checked={values?.achManagement}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="achManagement1"
                                                    name="achManagement1"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    // disabled={values.roleId == SuperAdminAccess}
                                                    // checked={values?.achManagement}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="achManagement2"
                                                    name="achManagement2"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    // disabled={values.roleId == SuperAdminAccess}
                                                    // checked={values?.achManagement}
                                                />
                                            </td>
                                            <td>
                                                <Field
                                                    type="checkbox"
                                                    id="achManagement3"
                                                    name="achManagement3"
                                                    className="form-check-input"
                                                    style={{
                                                        'hight': '1rem',
                                                        'width': '1rem',
                                                        'marginRight': "0.4rem"
                                                    }}
                                                    // disabled={values.roleId == SuperAdminAccess}
                                                    // checked={values?.achManagement}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table> */}

                <FormGroup className=" mb-3">
                  <Field
                    type="Role"
                    id="Role"
                    name="Role"
                    className="form-control selectbox"
                    component={Select}
                    menuPlacement="auto"
                    options={getRoleList}
                    value={getRoleList?.find(
                      (option) => option.value === values.roleId
                    )}
                    onChange={(data) => {
                      setFieldValue("roleId", data.value);
                      if (data.value === "1") {
                        setFieldValue("vendorAccess", true);
                      } else {
                        setFieldValue("vendorAccess", false);
                      }
                    }}
                  />
                  <Label className="form-label" htmlFor="Role">
                    Role
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t("validations.user.Role"),
                    values.roleId,
                    "required",
                    {
                      messages: {
                        required: __t("validations.user.Role"),
                      },
                    }
                  )}
                </FormGroup>
                <FormGroup className="mb-3 ">
                  <Field
                    type="checkbox"
                    id="vendorAccess"
                    name="vendorAccess"
                    className="form-check-input"
                    style={{
                      hight: "1rem",
                      width: "1rem",
                      marginRight: "0.4rem",
                    }}
                    disabled={
                      values.roleId == AdminAccess ||
                      values.roleId == SuperAdminAccess
                    }
                    checked={values.vendorAccess}
                  />
                  <Label
                    className="form-check-label fw-normal"
                    htmlFor="vendorAccess"
                  >
                    Allow this user to update the vendor account number
                  </Label>
                </FormGroup>
                <FormGroup className="mb-3 ">
                  <Field
                    type="checkbox"
                    id="addVendorContacts"
                    name="addVendorContacts"
                    className="form-check-input"
                    style={{
                      hight: "1rem",
                      width: "1rem",
                      marginRight: "0.4rem",
                    }}
                    disabled={
                      values.roleId == AdminAccess ||
                      values.roleId == SuperAdminAccess
                    }
                    checked={values.addVendorContacts}
                  />
                  <Label
                    className="form-check-label fw-normal"
                    htmlFor="addVendorContacts"
                  >
                    Allow this user to add the vendor contacts
                  </Label>
                </FormGroup>
                <FormGroup className="mb-3 ">
                  <Field
                    type="checkbox"
                    id="editVendorContacts"
                    name="editVendorContacts"
                    className="form-check-input"
                    style={{
                      hight: "1rem",
                      width: "1rem",
                      marginRight: "0.4rem",
                    }}
                    disabled={
                      values.roleId == AdminAccess ||
                      values.roleId == SuperAdminAccess
                    }
                    checked={values.editVendorContacts}
                  />
                  <Label
                    className="form-check-label fw-normal"
                    htmlFor="editVendorContacts"
                  >
                    Allow this user to update the vendor contacts
                  </Label>
                </FormGroup>
                <FormGroup className="mb-3 ">
                  <Field
                    type="checkbox"
                    id="achManagement"
                    name="achManagement"
                    className="form-check-input"
                    style={{
                      hight: "1rem",
                      width: "1rem",
                      marginRight: "0.4rem",
                    }}
                    disabled={values.roleId == SuperAdminAccess}
                    checked={values?.achManagement}
                  />
                  <Label
                    className="form-check-label fw-normal"
                    htmlFor="achManagement"
                  >
                    Allow this user to access the ACH Management
                  </Label>
                </FormGroup>
              </div>
              <div className="btn-box">
                <Button
                  color="primary"
                  className=""
                  title="user data"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {IsEditData ? "Update" : "Submit"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = ({ userList, roleList }) => {
  let getRoleList = null;
  if (roleList?.getRoles?.list?.length > 0) {
    getRoleList = roleList.getRoles.list.map((role) => ({
      label: role.roleName,
      value: role.id,
    }));
  }

  return {
    getRoleList,
    AddUser: userList?.addUser && userList.addUser,
    AddUserStatus: userList?.addUser?.status && userList.addUser.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    RoleListRequest: () => {
      dispatch(getRoleListRequest());
    },
    AddUserRequest: (data) => {
      dispatch(addUserListRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
