import { ACH_REQUEST, ACH_SUCCESS, CHANGE_STATUS_ACH_REQUEST, CHANGE_STATUS_ACH_SUCCESS, DOWNLOAD_ACH_REQUEST, DOWNLOAD_ACH_SUCCESS, GET_ACH_LIST_REQUEST, GET_ACH_LIST_SUCCESS } from "./type";

const initialState = {
    pdfAch: null,
    getAchList: null,
    statusChange: null,
    reqAchData: null
};

const AchReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACH_LIST_REQUEST:
            return { ...state, getAchList: null };
        case GET_ACH_LIST_SUCCESS:
            return { ...state, getAchList: action.payload };
        case DOWNLOAD_ACH_REQUEST:
            return { ...state, pdfAch: null };
        case DOWNLOAD_ACH_SUCCESS:
            return { ...state, pdfAch: action.payload };
        case CHANGE_STATUS_ACH_REQUEST:
            return { ...state, statusChange: null };
        case CHANGE_STATUS_ACH_SUCCESS:
            return { ...state, statusChange: action.payload };
        case ACH_REQUEST:
            return { ...state, reqAchData: null };
        case ACH_SUCCESS:
            return { ...state, reqAchData: action.payload };

        default:
            return state;
    }
};

export default AchReducer;