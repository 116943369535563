import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { CloseSvg } from "../../../components/CommonSvg";
function Vendorfilter({ handelShowFIlter, useFilter }) {
    const { setSearchState, setSearchCity, setSearchVendor, setIsFilter } = useFilter;
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [vendor, setVendor] = useState("");
    return (
        <>
            <Card className="filter_section card">
                <CardBody className="pb-2">
                    <h6 className="separate-head">Filter</h6>
                    <>
                        <form
                            className="hra-create row no-floating-label"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <FormGroup className="col mb-0">
                                <Input
                                    id="State"
                                    onChange={(e) => {
                                        setState(e.target.value);
                                        if (e.target.value?.length <= 0) {
                                            setState("");
                                            setSearchState("");
                                        }
                                    }}
                                    // onKeyDown={(e) => {
                                    //     if (e.key === "Enter") {
                                    //         setSearchState(state);
                                    //     }
                                    // }}
                                    value={state}
                                    type="search"
                                />
                                <Label className="form-label" htmlFor="assignStatus">
                                    State
                                </Label>
                            </FormGroup>
                            <FormGroup className="col mb-0">
                                <Input
                                    id="Cities"
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                        if (e.target.value?.length <= 0) {
                                            setCity("");
                                            setSearchCity("");
                                        }
                                    }}
                                    // onKeyDown={(e) => {
                                    //     if (e.key === "Enter") {
                                    //         setSearchCity(city);
                                    //     }
                                    // }}
                                    value={city}

                                    type="search"
                                />
                                <Label className="form-label" htmlFor="Cities">
                                    Territory and Cities
                                </Label>
                            </FormGroup>
                            <FormGroup className="col mb-0">
                                <Input
                                    id="VendorName"
                                    onChange={(e) => {
                                        setVendor(e.target.value);
                                        if (e.target.value?.length <= 0) {
                                            setVendor("");
                                            setSearchVendor("");
                                        }
                                    }}
                                    // onKeyDown={(e) => {
                                    //     if (e.key === "Enter") {
                                    //         setSearchVendor(vendor);
                                    //     }
                                    // }}
                                    type="search"
                                    value={vendor}

                                />
                                <Label className="form-label" htmlFor="VendorName">
                                    Vendor Name
                                </Label>
                            </FormGroup>
                            <Col lg="12" className="d-flex justify-content-end gap-2">
                                <Button
                                    className="mt-2"
                                    size="sm"
                                    color="primary"
                                    outline
                                    onClick={() => {
                                        setSearchState("");
                                        setSearchCity("");
                                        setSearchVendor("");
                                        setCity("");
                                        setVendor("");
                                        setState("")    
                                    }}
                                >
                                    Clear
                                </Button>
                                <Button
                                    className="mt-2"
                                    size="sm"
                                    color="primary"
                                    onClick={() => {
                                        setSearchState(state);
                                        setSearchCity(city);
                                        setSearchVendor(vendor);
                                    }}
                                >
                                    Filter
                                </Button>
                            </Col>
                        </form>
                    </>
                </CardBody>
            </Card>
        </>
    );
}
const mapStateToProps = ({ }) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendorfilter);
