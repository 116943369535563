import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";
import { PdfSvg } from "../../../components/CommonSvg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { viewPromotionListRequest } from "../../../redux/admin/promotion/action";
import { useEffect } from "react";
import { getFileNameFromUrl } from "../../../utils/authUtils";
import ViewCustomFields from "./ViewCustomFields";
import { RoleWiseModule } from "../../../utils/generalUtils";
import {
  AdminAccess,
  MarketingAccess,
  SuperAdminAccess,
} from "../../../constant/commonConstants";
import notassign from "../../../../src/assets/images/not-assign-promotio.png";

function ViewPromotion({ getPromotionsView, promotionsData, AssignRegions }) {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getPromotionsView(id);
  }, []);
  const EditPromotionButton = () => {
    navigate(`/admin/promotion/edit/${id}`, {
      state: { promotionsData },
    });
  };
  return (
    <>
      <CommonBackHeader
        backPath={"/admin/promotion"}
        HeaderName={"View Promotion"}
        isEditButton={
          RoleWiseModule(AdminAccess, MarketingAccess, SuperAdminAccess) && "Edit Promotion"
        }
        isEditUrl={
          RoleWiseModule(AdminAccess, MarketingAccess, SuperAdminAccess) && EditPromotionButton
        }
        createdDate={promotionsData.createdDate}
      />
      <div className="container hra-view-detail ">
        <Row>
          <Col lg={9}>
            <div className="hra-view-detail-inner-box">
              <Card className="p-0 mb-3">
                <CardBody className="row">
                  <h6 className="separate-head">Product Details</h6>
                  <div className="col-12">
                    <div className="hra-form-box">
                      <label>Title</label>
                      <span>{promotionsData.title}</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="hra-form-box">
                      <label>Description</label>
                      <span>{promotionsData.description}</span>
                    </div>
                  </div>
                  <div className="col-6">
                    {promotionsData.PromotionalOffersOthers &&
                      promotionsData.PromotionalOffersOthers.map((data, index) => {
                        return (
                          data.documentType === "DOCUMENT" && (
                            <div className="hra-form-box pdf-view-box" key={index}>
                              <label>Attachment</label>
                              <Link to={data.documentPath} target="_blank">
                                <PdfSvg />
                                <span>
                                  {getFileNameFromUrl(data.documentPath)}
                                </span>
                              </Link>
                            </div>
                          )
                        );
                      })}
                  </div>
                  <div className="col-6">
                    <div className="hra-form-box">
                      <label>Start-End Date</label>
                      <span>
                        {
                          promotionsData.startDate
                          +
                          " to " +
                          promotionsData.endDate
                        }
                      </span>
                    </div>
                  </div>

                  <ViewCustomFields promotionsData={promotionsData} />
                </CardBody>
              </Card>
              <Card className="p-0 mb-3 product-image-box">
                <CardBody>
                  <h6 className="separate-head">Product Images</h6>
                  <ul className="product-image-list small-scroll">
                    {promotionsData.PromotionalOffersOthers &&
                      promotionsData.PromotionalOffersOthers.map((data) => {
                        return (
                          data.documentType === "IMAGE" && (
                            <li
                              key={data.documentPath}
                              className={data.isBanner ? "product-main" : ""}
                            >
                              <img
                                src={data.documentPath}
                                alt="Product image"
                              />
                            </li>
                          )
                        );
                      })}
                  </ul>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg={3}>
            <div className="hra-view-detail-inner-box">
              <Card className="p-0 mb-3">
                <CardBody>
                  <h6 className="separate-head">Assigned</h6>
                  {(AssignRegions?.state?.length > 0 ||
                    AssignRegions?.territory?.length > 0 ||
                    promotionsData?.AllocatePromotions?.[0]?.mailingZip ||
                    AssignRegions?.county?.length > 0 ||
                    AssignRegions?.city?.length > 0) && promotionsData.isAssigned ? (
                    <div className="row assigned_promotion">
                      {AssignRegions?.state?.length > 0 && (
                        <div className="col-12">
                          <div className="hra-form-box">
                            <label>State</label>
                            {AssignRegions.state.map((d, index) => (
                              <span key={index}>{d}</span>
                            ))}
                          </div>
                        </div>
                      )}
                      {AssignRegions?.city?.length > 0 && (
                        <div className="col-12">
                          <div className="hra-form-box">
                            <label>City</label>
                            {AssignRegions.city.map((d, index) => (
                              <span key={index}>{d}</span>
                            ))}
                          </div>
                        </div>
                      )}
                      {AssignRegions?.county?.length > 0 && (
                        <div className="col-12">
                          <div className="hra-form-box">
                            <label>County</label>
                            {AssignRegions.county.map((d, index) => (
                              <span key={index}>{d}</span>
                            ))}
                          </div>
                        </div>
                      )}
                      {AssignRegions?.territory?.length > 0 && (
                        <div className="col-12">
                          <div className="hra-form-box">
                            <label>Territory</label>
                            {AssignRegions.territory.map((d, index) => (
                              <span key={index}>{d}</span>
                            ))}
                          </div>
                        </div>
                      )}
                      {promotionsData?.AllocatePromotions?.[0]?.mailingZip
                        ?.length > 0 && (
                          <div className="col-12">
                            <div className="hra-form-box">
                              <label>Mailing Zip</label>
                              {promotionsData.AllocatePromotions?.[0].mailingZip.map(
                                (x) => {
                                  return (
                                    <span >{x}</span>
                                  )
                                }
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  ) : (
                    <div className="not_assign_img w-100  d-flex justify-content-center align-items-center">
                      <img alt="No-data" src={notassign} />
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div >
    </>
  );
}
const mapStateToProps = ({ Promotions }) => {
  let promotionsData = [];
  let AssignRegions = [];
  if (Promotions.viewPromotions?.data) {
    promotionsData = Promotions.viewPromotions.data;
    AssignRegions = Promotions.viewPromotions;
  }
  return {
    promotionsData: promotionsData,
    AssignRegions: AssignRegions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionsView: (data) => dispatch(viewPromotionListRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPromotion);
