import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Home from "./pages/Admin/Dashboard/Home";
import "./App.scss";
import UserView from "./pages/Admin/Users/Users";
import Login from "./pages/Admin/Auth/Login/Login";
import Forgot from "./pages/Admin/Auth/Forgot/Forgot";
import ResetPassword from "./pages/Admin/Auth/Forgot/ResetPassword";
import Layout from "./components/Common/Layout/Layout";
import PageNotFound404 from "./components/NotFound/PageNotFound404";
import AddPromotion from "./pages/Admin/Promotion/AddPromotion";
import ViewPromotion from "./pages/Admin/Promotion/ViewPromotion";
import MemberList from "./pages/Admin/Members/MemberList";
import ViewMember from "./pages/Admin/Members/ViewMember";
import InquiryList from "./pages/Admin/Inquiry/InquiryList";
import PromotionList from "./pages/Admin/Promotion/PromotionList";
import InquiryForm from "./pages/Frontend/Inquiry/InquiryForm";
import LoginPage from "./pages/Frontend/Auth/LoginPage";
import ForgotPassword from "./pages/Frontend/Auth/ForgotPassword";
import ForgotLogin from "./pages/Frontend/Auth/ForgotLogin";
import HomePage from "./pages/Frontend/Home/HomePage";
import PromotionPage from "./pages/Frontend/Promotion/Promotion";
import ResetPasswordMember from "./pages/Frontend/Auth/ResetPasswordMember";
import LayoutMember from "./components/Common/Frontend/Layout/Layout";
import ProfileView from "./pages/Frontend/Profile/ProfileView";
import SupportPage from "./pages/Frontend/Support/Support";
import PromotionDetailsPage from "./pages/Frontend/Promotion/PromotionDetails";
import AgreementPage from "./pages/Frontend/Agreement/Agreement";
import NewPromotions from "./pages/Frontend/ViewPromotion/NewPromotions";
import ExpireSoon from "./pages/Frontend/ViewPromotion/ExpireSoon";
import AllPromotion from "./pages/Frontend/ViewPromotion/AllPromotion";
import AddAchManagement from "./pages/Frontend/AchManagement/AddAchManagement";
import ViewAchManagement from "./pages/Frontend/AchManagement/ViewAchManagement";
import AchManagement from "./pages/Admin/Ach/AchManagement";
import Rebate from "./pages/Frontend/Rebate/Rebate";
import MyPromotionPage from "./pages/Frontend/MyPromotion/MyPromotionPage";
import RebateList from "./pages/Admin/Rebate/RebateList"
import NewsLetter from "./pages/Admin/NewsLetter/NewsLetter";
import AddNewsLetter from "./pages/Admin/NewsLetter/AddNewsLetter";
import NewsLetterPage from "./pages/Frontend/Newsletter/NewsLetter";
import NewsLetterDetail from "./pages/Frontend/Newsletter/NewsLetterDetail";
import NewsLetterDetailPage from "./pages/Admin/NewsLetter/NewsLetterDetail";
import AddVendor from "./pages/Admin/Vendor/AddVendor";
import Vendor from "./pages/Frontend/Vendor/Vendor";
import VendorDetails from "./pages/Frontend/Vendor/VendorDetails";
import ViewVenderDetails from "./pages/Admin/Vendor/ViewVenderDetails";
import IndexVender from "./pages/Admin/Vendor/IndexVender";
import SupportList from "./pages/Admin/SupportRequest/SupportList";
import VendorContacts from "./pages/Admin/VendorContacts/VendorContacts";
import AddVendorContact from "./pages/Admin/VendorContacts/AddContact";
import UserGuide from "./pages/Frontend/UserGuide/UserGuide";
import ViewAchDetails from "./pages/Admin/Ach/ViewAchDetails";

function App() {
  const PrivateRoute = () => {
    return localStorage.getItem("user") ? <Outlet /> : <Navigate to="/home" />;
  };
  const MemberRoute = () => {
    return localStorage.getItem("hraMemberId") ? (
      <Outlet />
    ) : (
      <Navigate to="/home" />
    );
  };
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path="/admin/home" element={<Home />} />
          <Route path="/admin/user" element={<UserView />} />
          <Route path="/admin/vendor-contact/add" element={<AddVendorContact />} />
          <Route path="/admin/vendor-contact/edit/:id" element={<AddVendorContact />} />
          <Route path="/admin/vendor-contact" element={<VendorContacts />} />
          <Route path="/admin/promotion" element={<PromotionList />} />
          <Route path="/admin/promotion/add" element={<AddPromotion />} />
          <Route path="/admin/promotion/edit/:id" element={<AddPromotion />} />
          <Route path="/admin/promotion/view/:id" element={<ViewPromotion />} />
          <Route path="/admin/members" element={<MemberList />} />
          <Route path="/admin/members/view/:id" element={<ViewMember />} />
          <Route path="/admin/rebate" element={<RebateList />} />
          <Route path="/admin/newsletter" element={<NewsLetter />} />
          <Route path="/admin/newsletter/:id" element={<NewsLetterDetailPage />} />
          <Route path="/admin/newsletter/add" element={<AddNewsLetter />} />
          <Route path="/admin/newsletter/edit/:id" element={<AddNewsLetter />} />
          <Route path="/admin/inquiry" element={<InquiryList />} />
          <Route path="/admin/vendor" element={<IndexVender />} />
          <Route path="/admin/vendor/add" element={<AddVendor />} />
          <Route path="/admin/vendor/edit/:id" element={<AddVendor />} />
          <Route path="/admin/vendor/view/:id" element={<ViewVenderDetails />} />
          <Route path="/admin/ach" element={<AchManagement />} />
          <Route path="/admin/ach/:id" element={<ViewAchDetails />} />
          <Route path="/admin/support" element={<SupportList />} />
        </Route>
      </Route>
      <Route element={<LayoutMember />}>
        <Route path="/" element={<MemberRoute />}>
          <Route path="/profile" element={<ProfileView />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/member-application-user-guide" element={<UserGuide />} />

          <Route path="/promotion/latest" element={<NewPromotions />} />
          <Route path="/promotion/expiresoon" element={<ExpireSoon />} />
          <Route path="/promotion/all" element={<AllPromotion />} />
          <Route path="/promotion/view/:id" element={<PromotionDetailsPage />} />
          <Route path="/promotion" element={<PromotionPage />} />
          <Route path="/myPromotions" element={<MyPromotionPage />} />
          <Route path="/ach/add" element={<AddAchManagement />} />
          <Route path="/ach/view" element={<ViewAchManagement />} />
          <Route path="/ach/edit" element={<AddAchManagement />} />
          <Route path="/newsletter" element={<NewsLetterPage />} />
          <Route path="/newsletter/:id" element={<NewsLetterDetail />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/agreement" element={<AgreementPage />} />
          <Route path="/rebate" element={<Rebate />} />
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/vendor/:id" element={<VendorDetails />} />
        </Route>
      </Route>
      {/* frontend */}
      <Route index path="/" element={<LoginPage />} />
      <Route index path="/login" element={<LoginPage />} />
      <Route path="/forgotlogin" element={<ForgotLogin />} />
      <Route path="/inquiry" element={<InquiryForm />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPasswordMember />} />
      {/* frontend */}
      <Route path="/admin/login" element={<Login />} />
      <Route path="/admin/forgot" element={<Forgot />} />
      <Route
        path="/admin/reset-password/:id/:email"
        element={<ResetPassword />}
      />
      <Route path="*" element={<PageNotFound404 />} />
    </Routes>
  );
}

export default App;
