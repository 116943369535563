import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AddSvg, SettingSvg } from "../../../components/CommonSvg";
import TableView from "../../../components/Common/TableView/TableWithPagination";
import TableHeader from "../../../components/Common/TableView/TableHeader";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import {
  deleteVendorContactRequest,
  getVendorContactsListRequest,
  syncVendorContactsRequest,
} from "../../../redux/admin/vendorContacts/action";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import { getModuleAccess } from "../../../utils/authUtils";
import SupportStoreView from "./SupportStoreView";
import SupportTicket from "./SupportTicket";
import Vendorfilter from "./Vendorfilter";
import { AdminAccess, SuperAdminAccess } from "../../../constant/commonConstants";
import { RoleWiseModule } from "../../../utils/generalUtils";

function VendorContacts(props) {
  const { getVendorContactsListRequest, vendorContactsList, totalRecords, syncVendorContactsRequest, deleteVendorContactRequest } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchState, setSearchState] = useState("")
  const [searchCity, setSearchCity] = useState("")
  const [searchVendor, setSearchVendor] = useState("")
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [sort, setSort] = useState({ orderKey: "createdDate", orderBy: "ASC", });
  const navigate = useNavigate();
  const dispatchData = {
    searchText: searchData,
    state: searchState,
    cityAndTerritory: searchCity,
    vendor: searchVendor,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: sort.orderKey,
      orderBy: sort.orderBy,
    },
  };
  useEffect(() => {
    getVendorContactsListRequest(dispatchData);
  }, [dataPerPage, currentPage, searchData, searchState, searchCity, searchVendor, sort]);
  useEffect(() => {
    if (searchData || searchState || searchCity || searchVendor) {
      setCurrentPage(1);
    }
  }, [searchState, searchCity, searchVendor])
  const handleSyncToggle = async () => {
    const response = await commonConfirmBox(
      `Do you want to start vendor contacts sync?`
    );
    if (response) {
      syncVendorContactsRequest(dispatchData);
    }
  };
  const handleEdit = (row) => {
    navigate(`/admin/vendor-contact/edit/${row.id}`, { state: row });
  };
  const moduleAccess = getModuleAccess();
  const [selectedStore, setSelectedStore] = useState(null);
  const [createVendorTicket, setCreateVendorTicket] = useState(null);
  const [supportModal, setSupportModal] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const handelShowFIlter = () => {
    setIsFilter(!isFilter);
  };
  useEffect(() => {
    if (isFilter) {
      setIsFilter(false);
    }
  }, [isFilter]);
  const handleOpenSupportModal = () => {
    setSupportModal(!supportModal);
  };
  const handleTableChange = async (type, { sortField, sortOrder }) => {
    setSort({ orderKey: sortField, orderBy: sortOrder, })
  }

  return (
    <>
      <div className="container">
        <TableHeader
          hedingName="Vendor Contacts"
          searchBar={true}
          setSearchData={setSearchData}
          updateData={() =>
            getVendorContactsListRequest({
              searchText: "",
              pagination: {
                limit: dataPerPage,
                page: 1,
                orderKey: "createdDate",
                orderBy: "ASC",
              },
            })
          }
          settings={
            <>
              {moduleAccess?.addVendorContacts && (
                <button
                  className="creat_btn btn btn-secondary"
                  onClick={() => {
                    navigate("/admin/vendor-contact/add");
                  }}
                >
                  <AddSvg />
                  Create Vendor Contact
                </button>
              )}
              {RoleWiseModule(AdminAccess, SuperAdminAccess)&&<button
                className="creat_btn btn btn-secondary"
                onClick={handleSyncToggle}
              >
                <SettingSvg /> Sync
              </button>}
            </>
          }
          Filter={true}
          filterView={!isFilter}
          filterBody={<>
            <Vendorfilter
              handelShowFIlter={handelShowFIlter}
              useFilter={{ setSearchState, setSearchCity, setSearchVendor }}
            />
          </>
          }
        />
      </div>
      <TableView
        data={vendorContactsList ? vendorContactsList : []}
        columns={columns({
          handleEdit,
          deleteVendorContactRequest,
          dispatchData,
          handleOpenSupportModal,
          editVendorContacts: moduleAccess?.editVendorContacts || false,
          setCreateVendorTicket
        })}
        remote={{ sort: true }} // Enable remote sorting
        onTableChange={handleTableChange}
        dataPerPage={dataPerPage}
        currentPage={currentPage}
        setDataPerPage={setDataPerPage}
        setCurrentPage={setCurrentPage}
        pagination={true}
        rowEvents={(e, row, rowIndex) => { }}
        totalRecords={totalRecords}
        rowClassName={"cursor-pointer"}
      />
      {/* {selectedStore && (<></>
        // <CustomisedModal
        //   modalView={selectedStore}
        //   scrollable="true"
        //   customClass={
        //     "category-modal admin-support-ticket support-request-modal modal-lg "
        //   }
        //   modalName={
        //     <div className="ach_modal_header d-flex justify-content-between w-100">
        //       Create support ticket
        //     </div>
        //   }
        //   onModalDismiss={() => {
        //     setSelectedStore(false);
        //   }}
        // >
        //   <SupportTicket
        //     onModalDismiss={() => {
        //       setSelectedStore(false);
        //     }}
        //     selectedStore={selectedStore}
        //     dispatchData={dispatchData}
        //   />
        // </CustomisedModal>
      )} */}
      {supportModal && (
        <CustomisedModal
          modalView={supportModal}
          customClass={"category-modal admin-support-ticket modal-xl"}
          modalName={"Create support ticket"}
          onModalDismiss={handleOpenSupportModal}
        >
          <SupportStoreView
            onModalDismiss={() => {
              setSupportModal(false);
            }}
            createVendorTicket={createVendorTicket}
            closeAndOpen={{ handleOpenSupportModal, setSelectedStore }}
          />
        </CustomisedModal>
      )}
      {selectedStore && (
        <CustomisedModal
          modalView={selectedStore}
          scrollable="true"
          customClass={
            "category-modal admin-support-ticket support-request-modal modal-lg "
          }
          modalName={
            <div className="ach_modal_header d-flex justify-content-between w-100">
              {selectedStore?.details
                ? "Edit support ticket"
                : "Create support ticket"}
              <span>{selectedStore.hraMemberId}</span>
            </div>
          }
          onModalDismiss={() => {
            setSelectedStore(false);
          }}
        >
          <SupportTicket
            onModalDismiss={() => {
              setSelectedStore(false);
            }}
            selectedStore={{ ...selectedStore, ...createVendorTicket }}
            dispatchData={dispatchData}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = ({ vendorContacts }) => {
  let totalRecords = 0;
  let vendorContactsList = [];
  if (
    Array.isArray(vendorContacts?.vendorContacts?.list) &&
    vendorContacts.vendorContacts.list.length > 0
  ) {
    vendorContactsList = vendorContacts.vendorContacts.list;
    totalRecords = vendorContacts.vendorContacts.totalRecords;
  }
  return {
    vendorContactsList: vendorContactsList,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorContactsListRequest: (data) =>
      dispatch(getVendorContactsListRequest(data)),
    syncVendorContactsRequest: (data) =>
      dispatch(syncVendorContactsRequest(data)),
    deleteVendorContactRequest: (data) =>
      dispatch(deleteVendorContactRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorContacts);
