import {
    CHANGE_STATUS_ACH_REQUEST, CHANGE_STATUS_ACH_SUCCESS, DOWNLOAD_ACH_REQUEST, DOWNLOAD_ACH_SUCCESS, GET_ACH_LIST_REQUEST, GET_ACH_LIST_SUCCESS, ACH_REQUEST, ACH_SUCCESS,
} from "./type"

export const getAchListRequest = (data) => {
    return {
        type: GET_ACH_LIST_REQUEST,
        payload: data
    }
}
export const getAchListSuccess = (data) => {
    return {
        type: GET_ACH_LIST_SUCCESS,
        payload: data
    }
}

export const downloadAchpdfRequest = (data) => {
    return {
        type: DOWNLOAD_ACH_REQUEST,
        payload: data
    }
}
export const downloadAchpdfSuccess = (data) => {
    return {
        type: DOWNLOAD_ACH_SUCCESS,
        payload: data
    }
}
export const changeStatusAchRequest = (data) => {
    return {
        type: CHANGE_STATUS_ACH_REQUEST,
        payload: data
    }
}
export const changeStatusAchSuccess = (data) => {
    return {
        type: CHANGE_STATUS_ACH_SUCCESS,
        payload: data
    }
}

export const AchRequest = (data) => {
    return {
        type: ACH_REQUEST,
        payload: data
    }
}
export const AchSuccess = (data) => {
    return {
        type: ACH_SUCCESS,
        payload: data
    }
}