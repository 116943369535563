import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ACH_REQUEST,
  CHANGE_STATUS_ACH_REQUEST,
  DOWNLOAD_ACH_REQUEST,
  GET_ACH_LIST_REQUEST,
} from "./type";
import { invokeApi } from "../../../helper/axiosHelper";
import {
  AchSuccess,
  changeStatusAchSuccess,
  downloadAchpdfSuccess,
  getAchListRequest,
  getAchListSuccess,
} from "./action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { handleApiCallException } from "../../shared/action";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { viewAchRequest } from "../../frontend/ach/action";
function* AchList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/listAchManagement", data);
    if (response.status) {
      yield put(getAchListSuccess(response.data));
    } else {
      yield put(getAchListSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* AchStatus({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/statusAchManagement",
      data.value
    );
    if (response.status) {
      yield put(changeStatusAchSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      if (data.value.hraMemberId) {
        yield put(viewAchRequest({ hraMemberId: data.value.hraMemberId }));
      } else {
        yield put(getAchListRequest(data.dispatchData));
      }
    } else {
      yield put(changeStatusAchSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}
function* downloadPDFAch({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/achStorePDF",
      data,
      "arraybuffer"
    );
    if (response.status) {
      const fetchResponse = yield call(fetch, response.data.url);
      const blob = yield call([fetchResponse, "blob"]);
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);
      link.href = objectURL;
      link.download = response.data.url.substring(
        response.data.url.lastIndexOf("/") + 1
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
      yield put(downloadAchpdfSuccess(response.data));
    } else {
      yield put(downloadAchpdfSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* ACHRequest({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/achRequest", data);
    if (response.status) {
      yield put(AchSuccess(response));
      if (data.isMember) {
        yield put(viewAchRequest({ hraMemberId: data.hraMemberId }))
      } else {
        yield put(getAchListRequest({
          searchText: "",
          pagination: {
            limit: 10,
            page: 1,
            orderKey: "createdDate",
            orderBy: "ASC",
          },
        }));
      }
    } else {
      yield put(AchSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* watchAchRequest() {
  yield takeLatest(GET_ACH_LIST_REQUEST, AchList);
  yield takeLatest(DOWNLOAD_ACH_REQUEST, downloadPDFAch);
  yield takeLatest(CHANGE_STATUS_ACH_REQUEST, AchStatus);
  yield takeLatest(ACH_REQUEST, ACHRequest);
}

function* AchListSaga() {
  yield all([fork(watchAchRequest)]);
}

export default AchListSaga;
