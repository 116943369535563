import React, { useState } from "react";
import ViewTable from "../../../components/Common/ViewTable";
import { connect } from "react-redux";
import {
  AchRequest,
  changeStatusAchRequest,
  downloadAchpdfRequest,
  getAchListRequest,
} from "../../../redux/admin/Ach/action";
import { useEffect } from "react";
import {
  CloseSvg,
  Email_Svg,
  FileIconSvg,
  PdfSvg,
  RightSvg,
} from "../../../components/CommonSvg";
import { Badge, Button } from "reactstrap";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import ViewAchDetails from "./ViewAchDetails";
import { useNavigate } from "react-router-dom";
import { exportDataExportRequest } from "../../../redux/exportData/action";
import EmailModel from "../SupportRequest/Setting/emailModel";
import { commonConfirmBox } from "../../../helper/messagehelpers";

function AchManagement({
  getAchListRequest,
  AchList,
  pdfAchUrl,
  totalAchList,
  changeStatusAchRequest,
  downloadAchpdfRequest,
  exportDataExportRequest,
  AchRequest,
  emailSettingsData
}) {
  const [modalView, setModalView] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [settingView, setSettingView] = useState(false);
  const handleSettingsToggle = () => {
    setSettingView(!settingView);
  };
  const navigate = useNavigate();
  const ModalHeaderName =
    modalData !== null &&
    modalData.Member.firstName + " " + modalData.Member.lastName;
  const handleModalToggle = () => {
    setModalView(!modalView);
  };
  const dispatchData = {
    status: selectedStatus,
    searchText: searchData,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: "createdDate",
      orderBy: "ASC",
    },
  };

  useEffect(() => {
    getAchListRequest(dispatchData);
  }, [dataPerPage, currentPage, searchData, selectedStatus]);
  const columns = [
    {
      dataField: "Member.firstName",
      text: "User",
      sort: true,
      formatter: (cell, row) => {
        return row.Member.firstName + " " + row.Member.lastName;
      },
    },
    {
      dataField: "hraMemberId",
      text: "HRA #",
      sort: true,
    },
    {
      dataField: "routingNumber",
      text: "Routing No.",
      sort: true,
      formatter: (cell, row) => {
        return row.previousRoutingNumber
          ? row.previousRoutingNumber
          : row.routingNumber;
      },
    },

    {
      dataField: "accountNumber",
      text: "Account No.",
      sort: true,
      formatter: (cell, row) => {
        return row.previousAccountNumber
          ? row.previousAccountNumber
          : row.accountNumber;
      },
    },
    {
      dataField: "previousBankName",
      text: "Bank Name",
      sort: true,
      formatter: (cell, row) => {
        return row.previousBankName ? row.previousBankName : row.bankName;
      },
    },
    {
      dataField: "status",
      text: "",
      classes: "ach_action",
      headerEvents: {
        onClick: (e) => {
          if (e && e.stopPropagation) {
            e.stopPropagation();
          }
        },
      },
      formatter: (cell, row) => {
        return (
          <>
            <div className="ach-action-btns py-2">
              {/* <Button
                            className="view_btn_icon btn-link"
                            onClick={() => {
                                setModalData(row);
                                handleModalToggle();
                            }}
                        >
                            <ViewSvg />
                        </Button> */}

              {row?.achRequest === "REQUEST" ?
                 <>
                 <Button
                  size="sm"
                  className="approved_btn m-0 ms-3"
                  color="primary"
                  onClick={async (e) => {
                    if (e && e.stopPropagation) {
                      e.stopPropagation();
                    }
                    const response = await commonConfirmBox("Are you sure do you want to approve ACH.");
                    if (response) {
                      AchRequest({ achRequest: "APPROVED", hraMemberId: row?.hraMemberId })
                    } 
                  }}
                >
                  <RightSvg />
                </Button>
                <Button
                    size="sm"
                    color="primary"
                    className="reject_btn m-0"
                    onClick={async (e) => {
                      if (e && e.stopPropagation) {
                        e.stopPropagation();
                      }
                      const response = await commonConfirmBox("Are you sure do you want to reject ACH.");
                      if (response) {
                        AchRequest({ achRequest: "REJECT", hraMemberId: row?.hraMemberId })
                      } 
                    }}
                  >
                    <CloseSvg />
                  </Button>
                </> :
                <>
                  {row.achRequest === "APPROVED" && !row.achManagement[0]?.status && (
                    <Badge className="confirmed_badge p-0" color="primary">
                      <RightSvg />
                      ACCEPTED
                    </Badge>
                  )}
                </>
              }

              {row.achManagement[0]?.status === "PENDING" && (
                <>
                  <Button
                    size="sm"
                    className="approved_btn m-0"
                    color="primary"
                    onClick={async (e) => {
                      if (e && e.stopPropagation) {
                        e.stopPropagation();
                      }
                      const response = await commonConfirmBox("Are you sure do you want to approve ACH.");
                      if (response) {
                        changeStatusAchRequest({
                          value: {
                            status: "APPROVED",
                            achManagementId: row.achManagement[0].achManagementId,
                          },
                          dispatchData: dispatchData,
                        });
                      } 
                      
                    }}
                  >
                    <RightSvg />
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    className="reject_btn m-0"
                    onClick={async (e) => {
                      if (e && e.stopPropagation) {
                        e.stopPropagation();
                      }
                      const response = await commonConfirmBox("Are you sure do you want to approve ACH.");
                      if (response) {
                        changeStatusAchRequest({
                          value: {
                            status: "REJECTED",
                            achManagementId: row.achManagement[0].achManagementId,
                          },
                          dispatchData: dispatchData,
                        });
                      }
                    }}
                  >
                    <CloseSvg />
                  </Button>
                </>
              )}
              {row.achManagement[0]?.status === "APPROVED" && (
                <Badge className="confirmed_badge p-0" color="primary">
                  <RightSvg />
                  APPROVED
                </Badge>
              )}
              {row.achManagement[0]?.status === "REJECTED" && (
                <Badge className="reject_badge" color="danger">
                  <CloseSvg />
                  REJECTED
                </Badge>
              )}

              {row.achManagement[0]?.status === "APPROVED" && (
                <Button
                  className="view_btn_icon btn-link"
                  onClick={(e) => {
                    if (e && e.stopPropagation) {
                      e.stopPropagation();
                    }
                    downloadAchpdfRequest({ achManagementId: row.id });
                  }}
                >
                  <PdfSvg />
                </Button>
              )}
            </div >
          </>
        );
      },
    },
  ];

  const rowClassName = (row) => {
    let classes = null;
    if (row.achManagement[0]?.status === "PENDING") {
      classes = "pending_ach cursor-pointer";
    } else if (row.achManagement[0]?.status === "APPROVED") {
      classes = "approved_ach cursor-pointer";
    } else if (row.achManagement[0]?.status === "REJECTED") {
      classes = "rejected_ach cursor-pointer";
    }
    return classes;
  };

  const handleExport = () => {
    const isFiltered = !!selectedStatus?.length;
    exportDataExportRequest({
      type: "post",
      url: "/achListDataExport",
      data: {
        status: selectedStatus,
        isFilteredDataExport: isFiltered,
      },
    });
  };

  return (
    <div className="ach_table">
      <ViewTable
        data={AchList ? AchList : []}
        columns={columns}
        dataPerPage={dataPerPage}
        currentPage={currentPage}
        setDataPerPage={setDataPerPage}
        setCurrentPage={setCurrentPage}
        pagination={true}
        filterType={"AchManagement"}
        Filter={true}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        totalRecords={totalAchList}
        Header={true}
        setSearchData={setSearchData}
        updateData={() =>
          getAchListRequest({
            searchText: "",
            pagination: {
              limit: dataPerPage,
              page: 1,
              orderKey: "createdDate",
              orderBy: "ASC",
            },
          })
        }
        rowEvents={(e, row, rowIndex) => {
          navigate(`/admin/ach/${row.hraMemberId}`);
        }}
        hedingName="ACH Management"
        searchBar={true}
        rowClassName={rowClassName}
        settings={
          <>
            <button
              className="creat_btn btn btn-secondary"
              onClick={handleExport}
            >
              <FileIconSvg />
              Export Excel
            </button>

            <button
              className="creat_btn btn btn-secondary"
              onClick={handleSettingsToggle}
            >
              <Email_Svg />
              Email
            </button>
          </>
        }
      />
      {settingView && (
        <CustomisedModal
          modalView={settingView}
          customClass={
            "category-modal admin-support-ticket support-request-modal modal-lg"
          }
          modalName={"Email configuration"}
          onModalDismiss={handleSettingsToggle}
        >
          <EmailModel
            dispatchData={dispatchData}
            settingView={settingView}
            setSettingView={setSettingView}
            emailSettingData={{
              hours: "",
              email: emailSettingsData?.email,
              type: 3,
            }}
          />
        </CustomisedModal>
      )}
      {modalView && (
        <CustomisedModal
          modalView={modalView}
          customClass={"category-modal modal-xl inquiry_view_modal"}
          modalName={
            <div className="ach_modal_header">
              {ModalHeaderName}
              <span>{modalData.hraMemberId}</span>
            </div>
          }
          onModalDismiss={() => {
            setModalView(false);
          }}
        >
          <ViewAchDetails
            modalData={modalData}
            changeStatusAchRequest={changeStatusAchRequest}
            onModalDismiss={() => {
              setModalView(false);
            }}
            dispatchData={dispatchData}
          />
        </CustomisedModal>
      )}
    </div>
  );
}
const mapStateToProps = ({ AchManagementList }) => {
  let AchList = [];
  let totalAchList = null;
  let emailSettingsData;
  let pdfAch = AchManagementList.pdfAch?.url;
  if (AchManagementList?.getAchList?.list?.length > 0) {
    AchList = AchManagementList.getAchList.list;
    totalAchList = AchManagementList.getAchList.totalRecords;
    emailSettingsData = AchManagementList.getAchList.emailSettingData
  }
  return {
    AchList: AchList,
    totalAchList: totalAchList,
    pdfAchUrl: pdfAch,
    emailSettingsData: emailSettingsData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAchListRequest: (data) => dispatch(getAchListRequest(data)),
    changeStatusAchRequest: (data) => dispatch(changeStatusAchRequest(data)),
    downloadAchpdfRequest: (data) => dispatch(downloadAchpdfRequest(data)),
    exportDataExportRequest: (data) => dispatch(exportDataExportRequest(data)),
    AchRequest: (data) => dispatch(AchRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AchManagement);
