import React from "react";
import {
  BackSvg,
  CloseSvg,
  DeleteSvg,
  EditbtnSvg,
  PromotionsHistorySvg,
  RightSvg,
} from "../../../components/CommonSvg";
import { Col, Row } from "reactstrap";
import {
  removeAchRequest,
  viewAchRequest,
} from "../../../redux/frontend/ach/action";
import { useEffect } from "react";
import { getValueByKey } from "../../../utils/authUtils";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../components/NotFound/NoDataFound";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import { AchRequest } from "../../../redux/admin/Ach/action";
function ViewAchManagement({
  viewAchRequest,
  viewAchDetails,
  removeAchRequest,
  accountName,
  AchRequest,
}) {
  const navigate = useNavigate();
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
  localStorage.setItem("accountName", JSON.stringify(accountName?.accountName));
  useEffect(() => {
    viewAchRequest({ hraMemberId: hraMemberId });
  }, []);
  return (
    <>
      <div className="container">
        <div
          className={`section-head  ${viewAchDetails?.achManagement?.[0]?.status ? "ach_section_head" : ""
            }`}
        >
          <div>
            <button
              className="back_btn"
              title="back"
              onClick={() => {
                navigate("/home");
              }}
            >
              <BackSvg />
            </button>
            <h3>ACH Management</h3>
          </div>
          <div className="view_btn">
            {viewAchDetails !== null && (viewAchDetails?.accountNumber || viewAchDetails?.previousAccountNumber) ? (
              <div className="d-flex align-items-center gap-2">
                {viewAchDetails.achManagement?.[0]?.status === "PENDING" && (
                  <span className="badge pendding_badge">
                    <PromotionsHistorySvg />
                    Pending
                  </span>
                )}
                {viewAchDetails.achManagement?.[0]?.status === "APPROVED" && (
                  <span className="badge approved_badge">
                    <RightSvg />
                    Approved
                  </span>
                )}
                {viewAchDetails.achManagement?.[0]?.status === "REJECTED" && (
                  <span className="badge rejected_badge">
                    <CloseSvg />
                    Rejected
                  </span>
                )}
                {viewAchDetails.achManagement?.[0]?.status === "APPROVED" && (!viewAchDetails?.achRequest || viewAchDetails?.achRequest === "REJECT") && <button
                  className="btn btn-primary add_ach_btn m-0"
                  title="Edit ACH"
                  onClick={async () => {
                    // if (viewAchDetails?.achRequest) {
                    //   navigate("/ach/edit", { state: { viewAchDetails, accountName } });
                    //   return;
                    // }
                    const response = await commonConfirmBox("Are you sure you want to request edit ACH?");
                    if (response) {
                      AchRequest({ achRequest: "REQUEST", hraMemberId, isMember: true });
                    }
                  }}
                >
                  Send edit request
                </button>}

                {viewAchDetails?.achRequest === "APPROVED" && viewAchDetails.achManagement?.[0]?.status === "APPROVED" && <button
                  className="btn btn-primary small_btn ms-0"
                  title="Edit ACH"
                  onClick={() => {
                    navigate("/ach/edit", {
                      state: { viewAchDetails, accountName },
                    });
                  }}
                >
                  <EditbtnSvg />
                </button>}

                <button
                  className="btn btn-danger small_btn ms-0"
                  title="Delete ACH"
                  onClick={async () => {
                    const response = await commonConfirmBox(
                      "Are you sure you want to remove this ACH?"
                    );
                    if (response) {
                      removeAchRequest({ hraMemberId: hraMemberId });
                    }
                  }}
                >
                  <DeleteSvg />
                </button>
              </div>
            ) : (
              <>
                {(!viewAchDetails?.achRequest || viewAchDetails?.achRequest === "REJECT") &&
                  <button
                    className="btn btn-primary add_ach_btn"
                    title="Add ACH"
                    onClick={async () => {
                      const response = await commonConfirmBox("Are you sure you want to request ACH?");
                      if (response) {
                        AchRequest({ achRequest: "REQUEST", hraMemberId, isMember: true });
                      }
                    }}
                  >
                    {viewAchDetails?.achRequest === "APPROVED" ? "Add ACH" : "Request ACH"}
                  </button>
                }
                {viewAchDetails?.achRequest === "APPROVED" && <button
                  className="btn btn-primary add_ach_btn"
                  title="Add ACH"
                  onClick={async () => {
                    if (viewAchDetails?.achRequest === "APPROVED") {
                      navigate("/ach/add", { state: { viewAchDetails, accountName } });
                      return;
                    }

                    const response = await commonConfirmBox("Are you sure you want to request ACH?");
                    if (response) {
                      AchRequest({ achRequest: "REQUEST", hraMemberId, isMember: true });
                    }
                  }}
                >
                  Add ACH
                </button>}
              </>

            )}
            {viewAchDetails?.achRequest && viewAchDetails?.achRequest !== "APPROVED" && <span className="d-flex align-items-center"><b>re-request:</b> 
              {viewAchDetails?.achRequest === "REQUEST" && (
                    <span className="ms-2 badge pendding_badge">
                      <PromotionsHistorySvg />
                      Requested
                    </span>
                  )}
                  {viewAchDetails?.achRequest === "APPROVED" && (
                    <span className="ms-2 badge approved_badge">
                      <RightSvg />
                      Approved
                    </span>
                  )}
                  {viewAchDetails?.achRequest === "REJECT" && (
                    <span className="ms-2 badge rejected_badge">
                      <CloseSvg />
                      Rejected
                    </span>
                )}</span>}
          </div>
        </div>

        {viewAchDetails !== null && (viewAchDetails?.accountNumber || viewAchDetails?.previousAccountNumber) ? (
          <>
            <div className="ach_details mb-4 view-ach-details">
              <h3 className="d-flex align-items-center justify-content-between flex-wrap">
                Details
                {console.log(viewAchDetails)}
                <div className="account_name">
                  {accountName?.accountName && (
                    <>
                      <strong>Account Name: </strong> {accountName.accountName}
                    </>
                  )}
                </div>
              </h3>
              <Row>
                <Col sm={12} md={8} lg={9}>
                  <Row>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>HRA Member ID :</label>
                        {accountName?.hraMemberId && (
                          <>
                            <span>{accountName.hraMemberId}</span>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Name of Owner :</label>
                        {accountName?.firstName && (
                          <>
                            <span>
                              {accountName.firstName + " " + accountName.lastName}
                            </span>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Phone :</label>
                        {accountName?.phoneNumberMobileNumber && (
                          <>
                            <span>{accountName.phoneNumberMobileNumber}</span>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Street :</label>
                        {accountName?.streetAddress && (
                          <>
                            <span>{accountName.streetAddress}</span>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>City :</label>
                        {accountName?.city && (
                          <>
                            <span>{accountName.city}</span>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>State:</label>
                        {accountName?.state && (
                          <>
                            <span>{accountName.state}</span>
                          </>
                        )}
                      </div>
                    </Col>{" "}
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Zip Code:</label>
                        <span>{accountName?.postalCode || "-"}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Name of Corporation: </label>
                        <span>{accountName?.storeNameRegular || "-"}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Account No. :</label>
                        <span>
                          {viewAchDetails.accountNumber
                            ? viewAchDetails.accountNumber
                            : viewAchDetails.previousAccountNumber}
                        </span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Routing No. :</label>
                        <span>
                          {viewAchDetails.routingNumber
                            ? viewAchDetails.routingNumber
                            : viewAchDetails.previousRoutingNumber}
                        </span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>FEIN No. :</label>
                        <span>
                          {viewAchDetails.chequeNumber
                            ? viewAchDetails.chequeNumber
                            : viewAchDetails.previousChequeNumber}
                        </span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Bank Name. :</label>
                        <span>
                          {viewAchDetails.bankName
                            ? viewAchDetails.bankName
                            : viewAchDetails.previousBankName}
                        </span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Bank Address: </label>
                        <span>{viewAchDetails?.bankAddress ? viewAchDetails?.bankAddress : viewAchDetails?.previousBankAddress}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Bank City: </label>
                        <span>{viewAchDetails?.bankCity ? viewAchDetails?.bankCity : viewAchDetails?.previousBankCity}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Bank State: </label>
                        <span>{viewAchDetails?.bankState ? viewAchDetails?.bankState : viewAchDetails?.previousBankState}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Bank Zip: </label>
                        <span>{viewAchDetails?.bankZip ? viewAchDetails?.bankZip : viewAchDetails?.previousBankZip}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <div className="hra-light-form-box">
                        <label>Print Name: </label>
                        <span>{viewAchDetails?.printName ? viewAchDetails?.printName : viewAchDetails?.previousPrintName}</span>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>

                      <div className="hra-light-form-box">
                        <label>Account Type: </label>
                        <span>{viewAchDetails?.accountType ? viewAchDetails?.accountType : viewAchDetails?.previousAccountType}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={4} lg={3}>
                  <Row className="mb-3">
                    <Col>
                      <div className="hra-light-form-box">
                        <label>Signature: </label>
                        <img src={viewAchDetails?.sign ? viewAchDetails?.sign : viewAchDetails?.previousSign} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="ach_details">
              <h3>Document Image</h3>
              <Row>
                <Col sm={12} md={12}>
                  <div className="ach_document">
                    <img
                      alt="Document-image"
                      className="ach_img"
                      src={
                        viewAchDetails.achManagement?.[0]?.currentDocument
                          ? viewAchDetails.achManagement?.[0]?.currentDocument
                          : viewAchDetails.achManagement?.[0]?.previousDocument
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            <NoDataFound />
          </>
        )}
      </div >
    </>
  );
}
const mapStateToProps = ({ AchManagement }) => {
  let viewAchDetails = null;
  let accountName = null;
  if (
    AchManagement?.viewAch?.data?.list &&
    AchManagement?.viewAch?.data?.list !== undefined
  ) {
    viewAchDetails = AchManagement.viewAch.data.list;
  }

  if (AchManagement?.viewAch?.data?.accountName) {
    accountName = AchManagement.viewAch.data.accountName;
  }

  return {
    viewAchDetails: viewAchDetails,
    accountName: accountName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    viewAchRequest: (data) => dispatch(viewAchRequest(data)),
    removeAchRequest: (data) => dispatch(removeAchRequest(data)),
    AchRequest: (data) => dispatch(AchRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAchManagement);
