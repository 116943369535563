import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { CREATE_ACH_REQUEST, REMOVE_ACH_REQUEST, VIEW_ACH_REQUEST } from "./type";
import { createAchSuccess, removeAchSuccess, viewAchRequest, viewAchSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { invokeApi } from "../../../helper/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";

function* createAch({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", `/achManagement`, data);
        if (response.status) {
            yield put(createAchSuccess(response));
            ApiResponseMessage(response.message, SUCCESS)
        } else {
            ApiResponseMessage(response.message, DANGER)
            yield put(createAchSuccess(response));
            handleApiCallException(response.message);
        }
    } catch (error) {
        handleApiCallException(error);
    }
}
function* viewAch({ payload: data }) {
    try {
        
        const response = yield call(invokeApi, "post", `/viewAchManagement`, data);
        if (response.status) {
            yield put(viewAchSuccess(response));
        } else {
            yield put(viewAchSuccess(response));
            handleApiCallException(response.message);
        }

    } catch (error) {
        handleApiCallException(error);
    }
}
function* removeAch({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", `/removeAchManagement`, data);
        if (response.status) {
            yield put(removeAchSuccess(response));
            yield put(viewAchRequest(data));
        } else {
            yield put(removeAchSuccess(response));
            handleApiCallException(response.message);
        }
        ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER)
    } catch (error) {
        handleApiCallException(error);
    }
}


function* watchAchRequest() {
    yield takeLatest(CREATE_ACH_REQUEST, createAch);
    yield takeLatest(VIEW_ACH_REQUEST, viewAch);
    yield takeLatest(REMOVE_ACH_REQUEST, removeAch);
}

function* AchManagementSaga() {
    yield all([fork(watchAchRequest)]);
}

export default AchManagementSaga;
