import React, { useEffect, useRef } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { Formik, Form, FieldArray, Field } from "formik";
import Select from "react-select";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { useState } from "react";
import { __t } from "../../../i18n/translator";
import {
  Add_Svg,
  DeleteSvg,
  LoactionSvg,
  LocationSvg,
  ShopSvg,
  UserSvg,
} from "../../../components/CommonSvg";
import {
  clearCreateMultiStoreSuccess,
  createMultiStoreRequest,
  getMemberHraListRequest,
  getSubMemberHraListRequest,
} from "../../../redux/admin/members/action";
import AutocompleteInput from "../../../components/Common/Autocomplete/AutocompleteInput";

const PromotionModal = (props) => {
  const initialValue = {
    hraMemberId: "",
    subHraMember: [
      {
        subHraMemberId: "",
      },
    ],
  };
  const validator = useRef(new SimpleReactValidator());

  const [memberHraList, setMemberHraList] = useState([]);
  const [memberSubHraList, setMemberSubHraList] = useState([]);

  const [memberHraData, setMemberHraData] = useState();

  const [promotionInitialValue, setPromotionInitialValue] =
    useState(initialValue);

  const inputRef = useRef(null);
  useEffect(() => {
    setMemberHraList(props.memberHraList);
  }, [props.memberHraList]);
  useEffect(() => {
    setMemberSubHraList(props.memberSubHraList);
  }, [props.memberSubHraList]);

  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      let formValue = {
        hraMemberId: values.hraMemberId,
        subHraMemberId: values?.subHraMember?.map((x) => x.subHraMemberId),
      };

      props.createMultiStore(formValue);
    } else {
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    if (props.isCreateRecord) {
      setPromotionInitialValue(initialValue);
      props.clearCreateStore();
      props.onModalDismiss();
    }
  }, [props.isCreateRecord]);

  const getMemberHraList = (values) => {
    props.getMemberHraList({
      searchText: values,
    });
  };
  const getSubMemberHraList = (values) => {
    props.getSubMemberHraList({
      subSearchText: values,
    });
  };

  const filterStore = (values) => {
    let subHraMemberIds = values?.subHraMember?.map((x) => x?.subHraMemberId);
    if (values?.hraMemberId) {
      subHraMemberIds.push(values?.hraMemberId);
    }
    return memberHraList?.filter((x) => !subHraMemberIds?.includes(x.value));
  };
  const filterSubStore = (values) => {
    let subHraMemberIds = values?.subHraMember?.map((x) => x?.subHraMemberId);
    if (values?.hraMemberId) {
      subHraMemberIds.push(values?.hraMemberId);
    }
    return memberSubHraList?.filter((x) => !subHraMemberIds?.includes(x.value));
  };
  return (
    <>
      <Formik initialValues={promotionInitialValue} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="form-horizontal form-login row m-0">
            <div className="col-12 parents_store">
              <h4>Parents Store</h4>
              <div className="row">
                <FormGroup className="col-12 mb-0">
                  <AutocompleteInput
                    items={filterStore(values)}
                    placeholder="EX. HRA00000"
                    inputClassName="form-select store_find"
                    onChange={(value) => {
                      setFieldValue("hraMemberId", value);
                      getMemberHraList(value);
                      setMemberHraData(value);
                    }}
                    onSelect={(value) => {
                      setFieldValue("hraMemberId", value);
                      setMemberHraData(value);
                    }}
                    // isOnBlur={() => {
                    //   let memberData = memberHraList.filter(
                    //     (x) => x.value == values.hraMemberId
                    //   )[0];
                    //   if (!memberData) {
                    //     setFieldValue("hraMemberId", "");
                    //   }
                    // }}
                    value={values.hraMemberId}
                  />
                  {validator.current.message(
                    __t("validations.store_group.parent_store_required"),
                    values.hraMemberId,
                    "required",
                    {
                      messages: {
                        required: __t(
                          "validations.store_group.parent_store_required"
                        ),
                      },
                    }
                  )}
                </FormGroup>
              </div>
              {(() => {
                let memberData = memberHraList.filter(
                  (x) => x.value == values.hraMemberId
                )[0];
                return (
                  memberData && (
                    <div className="parents_store_details">
                      {memberData?.contactName && (
                        <div className="user_name">
                          <UserSvg />
                          <span>{memberData?.contactName}</span>
                        </div>
                      )}
                      {memberData?.storeNameRegular && (
                        <div className="store_name">
                          <ShopSvg />
                          <span>
                            {memberData?.storeNameRegular
                              ? memberData?.storeNameRegular
                              : ""}
                          </span>
                        </div>
                      )}
                      {console.log(memberData, "memberData")}
                      {memberData?.city && (
                        <div className="store_location">
                          <LocationSvg />
                          <span>
                            {memberData?.streetAddress}, {memberData?.city}, {memberData?.state}
                          </span>
                        </div>
                      )}
                      {memberData?.county && (
                        <div className="county_name">
                          <LoactionSvg />
                          <span>{memberData?.county}</span>
                        </div>
                      )}
                    </div>
                  )
                );
              })()}
            </div>
            <div className="col-12 sub_store">
              <h4>Sub Store</h4>
              <FieldArray
                name="subHraMember"
                render={(arrayHelpers) => (
                  <>
                    {values.subHraMember?.map((item, idx) => (
                      <div className="mb-2" key={idx}>
                        <div className="sub_store_field">
                          <FormGroup className="mb-0">
                            <AutocompleteInput
                              items={filterSubStore(values)}
                              placeholder="EX. HRA00000"
                              inputClassName="form-select"
                              onChange={(value) => {
                                setFieldValue(
                                  `subHraMember.${idx}.subHraMemberId`,
                                  value
                                );
                                getSubMemberHraList(value);
                              }}
                              onSelect={(value) => {
                                setFieldValue(
                                  `subHraMember.${idx}.subHraMemberId`,
                                  value
                                );
                              }}
                              // isOnBlur={() => {
                              //   let memberData = memberSubHraList.filter(
                              //     (x) => x.value == item.subHraMemberId
                              //   )[0];
                              //   if (!memberData) {
                              //     setFieldValue(
                              //       `subHraMember.${idx}.subHraMemberId`,
                              //       ""
                              //     );
                              //   }
                              // }}
                              value={item?.subHraMemberId}
                            />
                            {validator.current.message(
                              __t("validations.store_group.sub_store_required"),
                              item.subHraMemberId,
                              "required",
                              {
                                messages: {
                                  required: __t(
                                    "validations.store_group.sub_store_required"
                                  ),
                                },
                              }
                            )}
                          </FormGroup>
                          <Button
                            className={
                              values.subHraMember?.length == 1
                                ? " disabled-btn text-danger"
                                : " text-danger"
                            }
                            type="button"
                            color="link"
                            onClick={() => {
                              if (values.subHraMember?.length > 1) {
                                arrayHelpers.remove(idx);
                              }
                            }}
                          >
                            <DeleteSvg />
                          </Button>
                          {item?.subHraMemberId && <Button
                            color="link"
                            className="text-primary"
                            type="button"
                            onClick={() => {
                              arrayHelpers.insert(idx + 1, {
                                subHraMemberId: "",
                              });
                            }}
                          >
                            <Add_Svg />
                          </Button>}
                        </div>
                        {item?.subHraMemberId !== null &&
                          (() => {
                            let memberData = memberSubHraList.filter(
                              (x) => x.value == item.subHraMemberId
                            )[0];
                            return (
                              memberData && (
                                <div className="parents_store_details">
                                  {memberData?.contactName && (
                                    <div className="user_name">
                                      <UserSvg />
                                      <span>{memberData?.contactName} </span>
                                    </div>
                                  )}
                                  <div className="store_name">
                                    <ShopSvg />
                                    <span>
                                      {memberData?.storeNameRegular
                                        ? memberData?.storeNameRegular
                                        : ""}
                                    </span>
                                  </div>
                                  {memberData?.city && (
                                    <>
                                      <div className="store_location">
                                        <LocationSvg />
                                        <span>
                                          {memberData?.streetAddress}, {memberData?.city}, {memberData?.state}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                  {memberData?.storeNameRegular && (
                                    <div className="county_name">
                                      <LoactionSvg />
                                      <span>
                                        {memberData?.county
                                          ? memberData?.county
                                          : ""}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )
                            );
                          })()}
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
            <div className="btn-box mb-3">
              <Button
                color="primary"
                className=""
                title="user data"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = ({ Members }) => {
  let memberHraList = [];
  let isCreateRecord = "";
  let memberSubHraList = [];
  if (Members?.memberHraList?.list) {
    memberHraList = Members?.memberHraList?.list?.map((value) => ({
      value: value.hraMemberId,
      label: value.hraMemberId,
      city: value.city,
      county: value.county,
      state: value.state,
      streetAddress: value.streetAddress,
      contactName: value.contactName,
      storeNameRegular: value.storeNameRegular,
    }));
  }
  if (Members?.memberSubHraList?.subList) {
    memberSubHraList = Members?.memberSubHraList?.subList?.map((value) => ({
      value: value.hraMemberId,
      label: value.hraMemberId,
      city: value.city,
      county: value.county,
      state: value.state,      streetAddress: value.streetAddress,
      contactName: value.contactName,
      storeNameRegular: value.storeNameRegular,
    }));
  }
  isCreateRecord = Members?.createMultiStoreApiResponse;

  return {
    memberHraList: memberHraList,
    memberSubHraList: memberSubHraList,
    isCreateRecord: isCreateRecord,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMemberHraList: (data) => {
      dispatch(getMemberHraListRequest(data));
    },
    getSubMemberHraList: (data) => {
      dispatch(getSubMemberHraListRequest(data));
    },
    createMultiStore: (data) => {
      dispatch(createMultiStoreRequest(data));
    },
    clearCreateStore: () => {
      dispatch(clearCreateMultiStoreSuccess());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PromotionModal);
