import { Field, Form, Formik } from "formik";
import React from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { Button, FormGroup, Label, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { BackSvg, ClockSvg, DeleteSvg, FileIconSvg } from "../../../components/CommonSvg";
import { handleSingleFileUpload } from "../../../helper/imageSingalHelper";
import { useState } from "react";
import { getValueByKey } from "../../../utils/authUtils";
import { createAchRequest } from "../../../redux/frontend/ach/action";
import { useLocation, useNavigate } from "react-router-dom";
import { achInitialState, editAchInitialState } from "./CommonFunction";
import { useEffect } from "react";
import { getFileNameFromURL } from "../../../utils/generalUtils";
import SignaturePad from "./SignaturePad";

function AddAchManagement({ createAchRequest, AddACH }) {
    const validator = useRef(new SimpleReactValidator());
    const location = useLocation()
    const navigate = useNavigate()
    const [imageFile, setImageFile] = useState();
    const [imageFileData, setImageFileData] = useState(null);
    const [isRedirect, setIsRedirect] = useState(false);
    const hraMemberId = JSON.parse(getValueByKey("hraMemberId"));
    const accountName = JSON.parse(getValueByKey("accountName"))
    const isEditView = location.state?.viewAchDetails ? location.state.viewAchDetails : null
    const [achInitialValues] = useState(isEditView?.routingNumber ? achInitialState(location.state?.viewAchDetails) : editAchInitialState(location.state?.viewAchDetails))
    console.log("location.state?.viewAchDetails", location.state?.accountName)
    useEffect(() => {
        if (isEditView !== null) {
            const ImageData = isEditView?.achManagement?.map((d) => {
                if (isEditView.routingNumber) {
                    return {
                        fileNames: getFileNameFromURL(d.currentDocument),
                        imageUrl: d.currentDocument,
                    };
                } else {
                    return {
                        fileNames: getFileNameFromURL(d.previousDocument),
                        imageUrl: d.previousDocument,
                    };
                }

            });
            setImageFile(ImageData[0])
            setImageFileData(ImageData[0])
        }
    }, [isEditView])

    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            const formData = new FormData();
            formData.append("routingNumber", values.routingNumber);
            formData.append("accountNumber", values.accountNumber);
            formData.append("chequeNumber", values.chequeNumber);
            formData.append("bankName", values.bankName);
            formData.append("bankAddress", values.bankAddress);
            formData.append("bankCity", values.bankCity);
            formData.append("bankState", values.bankState);
            formData.append("bankZip", values.bankZip);
            formData.append("printName", values.printName);
            formData.append("accountType", values.accountType);
            if (!imageFile?.fileNames) {
                formData.append("imageFile", imageFile);
            }
            formData.append("hraMemberId", hraMemberId);
            formData.append("sign", values.sign);
            createAchRequest(formData)
            setIsRedirect(true)
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        if (AddACH === true && isRedirect === true) {
            navigate("/ach/view");
            setIsRedirect(false)
        }
    }, [AddACH])
    return <>
        <div className="container">
            <Formik initialValues={achInitialValues} onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                    <Form >
                        <>
                            <div className="section-head">
                                <div>
                                    <button
                                        className="back_btn"
                                        title="back"
                                        type="button"
                                        onClick={() => {
                                            navigate("/ach/view");
                                        }}
                                    >
                                        <BackSvg />
                                    </button>
                                    <h3>Add ACH Management</h3>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-auto"
                                        title="Send"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                            <div className="ach_details mb-4">
                                <h3 className="d-flex align-items-center justify-content-between flex-wrap">Details
                                    {accountName && <>
                                        <div className="account_name">
                                            <strong>Account Name: </strong>
                                            {accountName}
                                        </div>
                                    </>}
                                </h3>
                                <Row className="mb-3">
                                    <Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>HRA Member ID :</label>
                                            <span>
                                                {location.state?.accountName?.hraMemberId || "--"}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>Name of Owner :</label>
                                            <span>
                                                {location.state?.accountName?.lastName + ' ' + location.state?.accountName?.firstName || "--"}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>Phone :</label>
                                            <span>
                                                {location.state?.accountName?.phoneNumberMobileNumber || "--"}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>Street :</label>
                                            <span>
                                                {location.state?.accountName?.streetAddress || "--"}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>City :</label>
                                            <span>
                                                {location.state?.accountName?.city || "--"}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>State:</label>
                                            <span>
                                                {location.state?.accountName?.state || "--"}
                                            </span>
                                        </div>
                                    </Col> <Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>Zip :</label>
                                            <span>
                                                {location.state?.accountName?.postalCode || "--"}
                                            </span>
                                        </div>
                                    </Col><Col xs={12} md={6} lg={6} xl={3}>
                                        <div className="hra-light-form-box">
                                            <label>Name of Corporation: </label>
                                            <span>
                                                {location.state?.accountName?.storeNameRegular || "--"}
                                            </span>
                                        </div>
                                    </Col>
                                    {/* <Col sm={12} md={3}>
                                        <div className="hra-light-form-box">
                                            <label>Name on the Account: </label>
                                            <span>
                                                Abcc
                                            </span>
                                        </div>
                                    </Col> */}
                                </Row>
                            </div>
                            <Card>
                                <CardHeader className="p-3">
                                    <h3>Account Information</h3>
                                </CardHeader>
                                <CardBody>
                                    <div className="form-horizontal form-login row">
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="accountNumber"
                                                    name="accountNumber"
                                                    placeholder="example@example.com"
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={16}
                                                    minLength={7}
                                                />
                                                <Label className="form-label" htmlFor="accountNumber">
                                                    Account No.
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.accountNumber"),
                                                    values.accountNumber,
                                                    "required|matches:^[0-9]{16}$|numeric",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.accountNumber"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="routingNumber"
                                                    name="routingNumber"
                                                    className="form-control"
                                                    placeholder="Routing No."
                                                    type="text"
                                                    maxLength={12}
                                                    minLength={9}
                                                />
                                                <Label className="form-label" htmlFor="routingNumber">
                                                    Routing No. <span className="text-danger required">*</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.routingNumber"),
                                                    values.routingNumber,
                                                    "required|matches:^[0-9]{10}$|numeric",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.routingNumber"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="chequeNumber"
                                                    name="chequeNumber"
                                                    className="form-control"
                                                    placeholder="FEIN No."
                                                    type="text"
                                                    maxLength={9}
                                                    minLength={9}
                                                />
                                                <Label className="form-label" htmlFor="chequeNumber">
                                                    FEIN No.
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.chequeNumber"),
                                                    values.chequeNumber,
                                                    "required|matches:^[0-9]{9}$|numeric",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.chequeNumber"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="bankName"
                                                    name="bankName"
                                                    className="form-control"
                                                    placeholder="Bank Name."
                                                    type="text"
                                                />
                                                <Label className="form-label" htmlFor="bankName">
                                                    Bank Name
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.bankName"),
                                                    values.bankName,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.bankName"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="bankAddress"
                                                    name="bankAddress"
                                                    className="form-control"
                                                    placeholder="Bank Address."
                                                    type="text"
                                                />
                                                <Label className="form-label" htmlFor="bankAddress">
                                                    Bank Address
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.bankAddress"),
                                                    values.bankAddress,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.bankAddress"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="bankCity"
                                                    name="bankCity"
                                                    className="form-control"
                                                    placeholder="City."
                                                    type="text"
                                                />
                                                <Label className="form-label" htmlFor="bankCity">
                                                    City
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.bankCity"),
                                                    values.bankCity,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.bankCity"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="bankState"
                                                    name="bankState"
                                                    className="form-control"
                                                    placeholder="City."
                                                    type="text"
                                                />
                                                <Label className="form-label" htmlFor="bankState">
                                                    State
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.bankState"),
                                                    values.bankState,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.bankState"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="bankZip"
                                                    name="bankZip"
                                                    className="form-control"
                                                    placeholder="Zip code"
                                                    type="text"
                                                />
                                                <Label className="form-label" htmlFor="bankZip">
                                                    Zip Code
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.bankZip"),
                                                    values.bankZip,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.bankZip"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup className="form-control account-name">
                                                <Label className="form-label" htmlFor="printName">Type of Account:</Label>
                                                <div className="d-flex gap-2">
                                                    <div className="d-flex gap-1">
                                                        <Field
                                                            type="radio"
                                                            name="accountType"
                                                            value="checking"
                                                            id="checking"
                                                        // onChange={handleChange}
                                                        />
                                                        <Label className="form-check-label" htmlFor="checking">
                                                            Checking
                                                        </Label>
                                                    </div>
                                                    <div className="d-flex gap-1">
                                                        <Field
                                                            type="radio"
                                                            name="accountType"
                                                            value="savings"
                                                            id="savings"
                                                        // onChange={handleChange}
                                                        />
                                                        <Label className="form-check-label" htmlFor="savings">
                                                            Savings
                                                        </Label>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            {validator.current.message(
                                                __t("validations.ACH.accountType"),
                                                values.accountType,
                                                "required",
                                                {
                                                    messages: {
                                                        required: __t("validations.ACH.accountType"),
                                                    },
                                                }
                                            )}
                                        </Col>
                                        <Col sm={12} md={6} lg={6} xl={3}>
                                            <FormGroup>
                                                <Field
                                                    id="printName"
                                                    name="printName"
                                                    className="form-control"
                                                    placeholder="Zip code"
                                                    type="text"
                                                />
                                                <Label className="form-label" htmlFor="printName">
                                                    Print Name
                                                    <span className="text-danger required"> *</span>
                                                </Label>
                                                {validator.current.message(
                                                    __t("validations.ACH.printName"),
                                                    values.printName,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.printName"),
                                                        },
                                                    }
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                            <Row>
                                <Col md={12} lg={9}>
                                    <Card>
                                        <CardHeader className="py-2">
                                            <h3>Document Image  <small className="text-danger required">*</small></h3>
                                            {imageFileData && <button className="btn btn-primary delete_document"
                                                title="Delete ACH"
                                                onClick={() => {
                                                    setImageFile(null)
                                                    setImageFileData(null)
                                                    setFieldValue("imageFile", null);
                                                }}
                                                type="button"
                                            >
                                                <DeleteSvg /> Delete
                                            </button>}
                                        </CardHeader>
                                        <CardBody>
                                            <div className="ach_document">
                                                {!imageFileData ?
                                                    <div className="dropzone">
                                                        <label htmlFor="imageFile" className="dropzone-container">
                                                            <div className="file-icon">
                                                                <FileIconSvg />
                                                            </div>
                                                            <div className="dropzone-title">
                                                                Drag and drop your files or&nbsp;
                                                                <span className="browse  d-inline-block text-center">
                                                                    {" "}
                                                                    browse
                                                                </span>{" "}
                                                                your files
                                                            </div>
                                                            <input
                                                                id="imageFile"
                                                                name="imageFile"
                                                                type="file"
                                                                className="form-control"
                                                                accept="image/*"
                                                                onChange={(e) =>
                                                                    handleSingleFileUpload(
                                                                        setImageFile,
                                                                        setFieldValue,
                                                                        "imageFile",
                                                                        setImageFileData,
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </label>
                                                    </div> :
                                                    <img src={imageFileData.imageUrl} alt={imageFileData.fileNames} />
                                                }
                                                {validator.current.message(
                                                    __t("validations.ACH.check_image"),
                                                    values.imageFile,
                                                    "required",
                                                    {
                                                        messages: {
                                                            required: __t("validations.ACH.check_image"),
                                                        },
                                                    }
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>


                                </Col>
                                <Col md={12} lg={3}>
                                    <SignaturePad setFieldValue={setFieldValue} signImage={values.sign} />
                                    {validator.current.message(
                                        __t("validations.ACH.Signature"),
                                        values.sign,
                                        "required",
                                        {
                                            messages: {
                                                required: __t("validations.ACH.Signature"),
                                            },
                                        }
                                    )}
                                </Col>
                            </Row>

                        </>
                    </Form>
                )}
            </Formik>
        </div>

    </>;
}
const mapStateToProps = ({ AchManagement }) => {
    return {
        AddACH: AchManagement?.createAch?.status
    }
}
const mapDispatchToProps = (dispatch) => {
    return { createAchRequest: (data) => dispatch(createAchRequest(data)) }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddAchManagement);