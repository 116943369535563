export function getImageFileNameFromURL(url) {
  const segments = url?.split("/");
  const fileName = segments?.pop();
  return fileName;
}
export function getFileNameFromURL(url) {
  const segments = url?.split("/");
  let fileName = segments?.pop();
  // Remove special characters from the filename
  fileName = fileName?.replace(/%/g, " ");
  return fileName;
}
export const getInitials = function (string) {
  if (string) {
    var names = string?.split(" "),
      initials = names[0]?.substring(0, 1)?.toUpperCase();
    if (names?.length > 1) {
      initials += names[names?.length - 1]?.substring(0, 1)?.toUpperCase();
    }
    return initials;
  } else {
    return "";
  }
};

export const setRoleStatusColor = (isRole) => {
  let role = isRole;
  let roleClass;
  switch (role) {
    case "Marketing":
      roleClass = "bg-warning";
      break;
    case "Staff":
      roleClass = "bg-info";
      break;
    case "Admin":
      roleClass = "bg-primary";
      break;
    default:
      roleClass = "badge role-badge";
      break;
  }

  return roleClass;
};

export const RoleWiseModule = (AccessRole, StaffAccess, MarketingAccess, SuperAdminAccess) => {
  const roleId = JSON.parse(JSON.parse(localStorage.getItem("roleId")));
  return (
    roleId === AccessRole ||
    StaffAccess === roleId ||
    MarketingAccess === roleId || SuperAdminAccess === roleId
  );
};

export const getInitialsFirstCharechter = (firstName, lastName) => {
  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();
  return `${firstInitial}${lastInitial}`;
};

function clearLocalStorage() {
  localStorage.removeItem("zohoToken");
}

export const setMarkerAndStartCountdown = (zohoToken, zohoTokenTime) => {
  const countdownInterval = setInterval(() => {
    if (
      !localStorage.getItem("zohoToken") &&
      !localStorage.getItem("zohoTokenTime")
    ) {
      clearInterval(countdownInterval);
    } else {
      clearLocalStorage();
    }
  }, 55 * 60 * 1000);
};
export function isPDF(url) {
  return url?.toLowerCase().endsWith(".pdf");
}
export function isImage(url) {
  if (!url) {
    return false;
  }
  const allowedExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"]; // Add more extensions as needed

  const lowercasedUrl = url.toLowerCase();
  return allowedExtensions.some((extension) =>
    lowercasedUrl.endsWith(`.${extension}`)
  );
}

export function isValidHttpUrl(data) {
  let url;

  try {
    url = new URL(data);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
export const isZohoSyncEnabled = process.env.REACT_APP_IS_ZOHO_SYNC_ENABLE
  ? !JSON.parse(process.env.REACT_APP_IS_ZOHO_SYNC_ENABLE)
  : null;
