import React, { useEffect } from "react";
import { Badge, Button, Card, CardBody } from "reactstrap";
import { CloseSvg, RightSvg } from "../../../components/CommonSvg";
import { connect } from "react-redux";
import { viewAchRequest } from "../../../redux/frontend/ach/action";
import { useParams } from "react-router-dom";
import { changeStatusAchRequest } from "../../../redux/admin/Ach/action";
import CommonBackHeader from "../../../components/Common/CommonBackHeader";

function ViewAchDetails({
  viewAch,
  changeStatusAchRequest,
  viewAchRequest,
  memberData,
}) {
  console.log(viewAch, "viewAchviewAch");

  const { id } = useParams();
  useEffect(() => {
    viewAchRequest({ hraMemberId: id });
  }, []);
  return (
    <>
      {" "}
      <CommonBackHeader
        backPath={"/admin/ach"}
        HeaderName={"View ACH details"}
      />
      <div className="container hra-view-detail view_member_card">
        <div className="ach_action view_ach text-center">
          {viewAch?.achManagement[0]?.status === "PENDING" && (
            <>
              <Button
                size="sm"
                color="success"
                className="approved_btn"
                onClick={() => {
                  changeStatusAchRequest({
                    value: {
                      status: "APPROVED",
                      achManagementId:
                        viewAch?.achManagement[0].achManagementId,
                      hraMemberId: id,
                    },
                  });
                }}
              >
                <RightSvg />
                Approve
              </Button>
              <Button
                size="sm"
                color="danger"
                className="reject_btn"
                onClick={() => {
                  changeStatusAchRequest({
                    value: {
                      status: "REJECTED",
                      achManagementId:
                        viewAch?.achManagement[0].achManagementId,
                      hraMemberId: id,
                    },
                  });
                }}
              >
                <CloseSvg />
                Reject
              </Button>
            </>
          )}
          {viewAch?.achManagement[0]?.status === "APPROVED" && (
            <Badge color="primary" className="confirmed_badge">
              <RightSvg />
              APPROVED
            </Badge>
          )}
          {viewAch?.achManagement[0]?.status === "REJECTED" && (
            <Badge color="danger" className="reject_badge">
              <CloseSvg />
              REJECTED
            </Badge>
          )}
        </div>
        <div className="hra-view-detail-inner-box">
          <Card className="p-0 mb-3">
            <CardBody className="row">
              <div className="d-flex justify-content-between mb-2">
                <h6 className="separate-head"> Details</h6>
                <div className="view_member_head">
                  <span className="view_member_hra">
                    {viewAch?.hraMemberId || "--"}
                  </span>
                </div>
              </div>{" "}
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Name of Owner :</label>
                  <span>
                    {(memberData?.firstName ? memberData.firstName : "--") +
                      " " +
                      (memberData?.lastName ? memberData.lastName : "")}
                  </span>
                </div>
              </div>{" "}
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Phone :</label>
                  {memberData?.phoneNumberMobileNumber && (
                    <>
                      <span>{memberData.phoneNumberMobileNumber}</span>
                    </>
                  )}
                </div>
              </div>{" "}
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Street :</label>
                  {memberData?.streetAddress && (
                    <>
                      <span>{memberData.streetAddress}</span>
                    </>
                  )}
                </div>
              </div>{" "}
              <div className="col-3">
                <div className="hra-form-box">
                  <label>City :</label>
                  {memberData?.city && (
                    <>
                      <span>{memberData.city}</span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-3">
                <div className="hra-form-box">
                  <label>State:</label>
                  {memberData?.state && (
                    <>
                      <span>{memberData.state}</span>
                    </>
                  )}
                </div>
              </div>{" "}
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Zip Code:</label>
                  <span>{memberData?.postalCode || "-"}</span>
                </div>
              </div>{" "}
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Name of Corporation: </label>
                  <span>{memberData?.storeNameRegular || "-"}</span>
                </div>
              </div>{" "}
              <div className="col-3">
                <div className="hra-form-box">
                  <label>Account Name:</label>
                  <span>
                    {memberData?.accountName ? memberData.accountName : ""}
                  </span>
                </div>
              </div>{" "}

            </CardBody>
          </Card>
          <div
            className={
              viewAch?.previousRoutingNumber && viewAch?.routingNumber
                ? "Previous"
                : "current"
            }
          >
            <div className="ach-modal_content">
              {viewAch?.previousRoutingNumber && (
                <div className="ach_details_body card">
                  <h6 className={!(viewAch?.previousRoutingNumber && viewAch?.routingNumber) ? "" : "previous_record"}>
                    {viewAch?.previousRoutingNumber && viewAch?.routingNumber
                      ? "Previous Record"
                      : "Current Record"}
                  </h6>
                  <ul>
                    <li>
                      <label>Routing No. :</label>
                      <span>{viewAch?.previousRoutingNumber}</span>
                    </li>
                    <li>
                      <label>Account No. :</label>
                      <span>{viewAch?.previousAccountNumber}</span>
                    </li>
                    <li>
                      <label>FEIN No. :</label>
                      <span>{viewAch?.previousChequeNumber}</span>
                    </li>
                    <li>
                      <label>Bank Name :</label>
                      <span>{viewAch?.previousBankName}</span>
                    </li>
                    <li>
                      <label>Bank Address :</label>
                      <span>{viewAch?.previousBankAddress}</span>
                    </li>
                    <li>
                      <label>Bank City :</label>
                      <span>{viewAch?.previousBankCity}</span>
                    </li>
                    <li>
                      <label>Bank State :</label>
                      <span>{viewAch?.previousBankState}</span>
                    </li>
                    <li>
                      <label>Bank Zip :</label>
                      <span>{viewAch?.previousBankZip}</span>
                    </li>
                    <li>
                      <label>Print Name :</label>
                      <span>{viewAch?.previousPrintName}</span>
                    </li>
                    <li>
                      <label>Account Type :</label>
                      <span>{viewAch?.previousAccountType}</span>
                    </li> 
                    <li>
                      <label>Signature :</label>
                      <img src={viewAch?.previousSign} />
                    </li>
                    <li className={!(viewAch?.previousRoutingNumber && viewAch?.routingNumber) ? "ach_check_img" : ""}>
                      <label className="text-center">Check Image :</label>
                      <div className="d-flex justify-content-center checkImg">
                        <img src={viewAch?.achManagement[0]?.previousDocument} />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
              {viewAch?.routingNumber && (
                <div className="ach_details_body card">
                  <h6>
                    {viewAch?.previousRoutingNumber && viewAch?.routingNumber
                      ? "Updated Record"
                      : "Current Record"}
                  </h6>
                  <ul>
                    <li>
                      <label>Routing No. :</label>
                      <span>{viewAch?.routingNumber}</span>
                    </li>
                    <li>
                      <label>Account No. :</label>
                      <span>{viewAch?.accountNumber}</span>
                    </li>
                    <li>
                      <label>FEIN No. :</label>
                      <span>{viewAch?.chequeNumber}</span>
                    </li>
                    <li>
                      <label>Bank Name :</label>
                      <span>{viewAch?.bankName}</span>
                    </li>
                    <li>
                      <label>Bank Address :</label>
                      <span>{viewAch?.bankAddress}</span>
                    </li>
                    <li>
                      <label>Bank City :</label>
                      <span>{viewAch?.bankCity}</span>
                    </li>
                    <li>
                      <label>Bank State :</label>
                      <span>{viewAch?.bankState}</span>
                    </li>
                    <li>
                      <label>Bank Zip :</label>
                      <span>{viewAch?.bankZip}</span>
                    </li>
                    <li>
                      <label>Print Name :</label>
                      <span>{viewAch?.printName}</span>
                    </li>
                    <li>
                      <label>Account Type :</label>
                      <span>{viewAch?.accountType}</span>
                    </li>
                    <li>
                      <label>Signature :</label>
                      <img src={viewAch?.sign}  />
                    </li>
                    <li>
                      <label className="text-center">Check Image :</label>
                      <div className="d-flex justify-content-center checkImg">
                        <img src={viewAch?.achManagement[0]?.currentDocument} />
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = ({ AchManagement }) => {
  const viewAch = AchManagement?.viewAch?.data?.list || null;
  const memberData = AchManagement?.viewAch?.data?.accountName || null;
  return { viewAch, memberData };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeStatusAchRequest: (data) => dispatch(changeStatusAchRequest(data)),
    viewAchRequest: (data) => dispatch(viewAchRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAchDetails);
