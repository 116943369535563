import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { handleApiCallException } from "../../shared/action";
import { emailSettingSuccess, settingSuccess } from "./action";
import * as type from "./type";
import { getVendorListRequest } from "../vendor/action";
import { getSupportTicketRequest } from "../supportTickets/action";
import { getAchListRequest } from "../Ach/action";

function* getSetting({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/emailSetting", data);
    if (response.status) {
      yield put(settingSuccess(response.data));
      yield put(
        getVendorListRequest({
          searchText: "",
          pagination: {
            limit: 10,
            page: 1,
            orderKey: "createdDate",
            orderBy: "ASC",
          },
        })
      );
      ApiResponseMessage(response.message, SUCCESS);
    } else {
      ApiResponseMessage(response.message, DANGER);
      yield put(settingSuccess([]));
    }
  } catch (error) {
    yield put(settingSuccess([]));
    handleApiCallException(error);
  }
}
function* getEmailSetting({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/emailSettings", data);
    if (response.status) {
      yield put(emailSettingSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      data?.isACH ?
        yield put(getAchListRequest(
          {
            searchText: "",
            pagination: {
              limit: 10,
              page: 1,
              orderKey: "createdDate",
              orderBy: "ASC",
            },
          }
        ))
        :
        yield put(
          getSupportTicketRequest({
            searchText: "",
            pagination: {
              limit: 10,
              page: 1,
              orderKey: "createdDate",
              orderBy: "ASC",
            },
          })
        );
    } else {
      ApiResponseMessage(response.message, DANGER);
      yield put(emailSettingSuccess([]));
    }
  } catch (error) {
    yield put(emailSettingSuccess([]));
    handleApiCallException(error);
  }
}

function* watchSetting() {
  yield takeEvery(type.SETTING_REQUEST, getSetting);
  yield takeEvery(type.EMAIL_SETTING_REQUEST, getEmailSetting);
}
export default function* SettingSaga() {
  yield all([fork(watchSetting)]);
}
