import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import SettingView from "./SettingView";

export default function EmailModel({
    setSettingView,
    emailSettingData,
}) {
    const [tab, setTab] = useState("email");
    return (
        <div>
            {/* <div className="d-flex justify-content-center mb-3">
                <Nav tabs className="email-configration">
                    <NavItem>
                        <NavLink
                            className={tab === "email" ? "active" : ""}
                            onClick={() => {
                                setTab("email");
                            }}
                        >
                            Email configuration
                        </NavLink>
                    </NavItem>
                </Nav>
            </div> */}

            <TabContent activeTab={tab}>
                <TabPane tabId="email">
                    <SettingView
                        isACH={true}
                        emailSettingData={emailSettingData}
                        onModalDismiss={() => {
                            setSettingView(false);
                        }}
                    />
                </TabPane>
            </TabContent>
        </div>
    );
}
