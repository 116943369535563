import React, { useEffect, useState } from "react";
import { BackSvg } from "../../../components/CommonSvg";
import { useNavigate } from "react-router-dom";
import Iframe from "react-iframe";

const UserGuide = () => {
  const UserPdf = `${process.env.REACT_APP_API}/userGuide/MEMBER_USER_GUIDE.pdf`
  const navigate = useNavigate();
  const [googleViewerUrl, setGoogleViewerUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const screenWidth = window.screen.width;

  // Effect to handle URL configuration based on screen width
  useEffect(() => {
    if (UserPdf) {
      if (screenWidth > 786) {
        setGoogleViewerUrl(UserPdf);
      } else {
        // setGoogleViewerUrl(UserPdf);
        const encodedUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(UserPdf)}`;
        console.log(encodedUrl, "encodedUrl")
        setGoogleViewerUrl(encodedUrl);
      }
    }
  }, [screenWidth]);

  return (
    <div className="container">
      <div className="section-head">
        <div>
          <button
            className="back_btn"
            title="Back"
            onClick={() => navigate("/home")}
          >
            <BackSvg />
          </button>
          <h3>Member Application User Guide</h3>
        </div>
      </div>

      <div className="head_hraid">
        <div id="pdfContainer" className="agreement_pdf_container">
          {/* Show loader for smaller screens */}
          {screenWidth < 786 && isLoading && (
            <div className="w-100 text-center agreement_loader">
              <div className="spinner-border" role="status"></div>
            </div>
          )}

          {/* Display the iframe when the URL is ready */}
          {googleViewerUrl && (
            <Iframe
              type="application/pdf"
              src={googleViewerUrl}
              width="100%"
              height="600px"
              onLoad={() => setIsLoading(false)}
            >
              <p>
                Your browser does not support embedded PDFs. You can download
                the PDF <a href={UserPdf} download>here</a>.
              </p>
            </Iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserGuide;
